const checklistTypeValues = [
  { label: 'Inspección técnica', value: 'mechanic' },
  { label: 'Recepción', value: 'reception' },
  { label: 'Pre-Inpección', value: 'pre_inspection' },
  { label: 'Salida', value: 'output' },
  { label: 'Presupuesto', value: 'quote' }
];

export const typeReview = [
  { label: 'Revisión Bueno/Malo', value: 'NormalReview' },
  { label: 'Revisión por Rango Porcentual', value: 'PercentageRangeReview' }
];

export default checklistTypeValues;
