import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';

import { validateToken } from '../../actions/auth';
import { sendAlert } from '../../actions/utils';
import { indexProjectDetailsRequest } from '../../requests/projectDetails';
import downloadFile from '../../services/utils';
import columns from './Columns';
import ProjectDetailDataTable from './ProjectDetailDataTable';

const AssociatePurchaseIndex = () => {
  const { user: currentUser } = useSelector(state => state.auth);
  const { id, currentBranchOffice } = currentUser;

  const dispatch = useDispatch();
  const [queries, setQueries] = useState({});
  const [defaultParams, setDefaultParams] = useState({
    branch_office_id: '',
    sort_associate_purchase: [
      'projects.code DESC',
      'request_purchase DESC',
      'purchase_management_details.id DESC',
      'purchase_made ASC'
    ]
  });

  const handleCurrentBranchOffice = () => {
    if (Object.keys(currentBranchOffice).length > 0) {
      setDefaultParams({ ...defaultParams, branch_office_id: currentBranchOffice.value });
    }
  };

  useEffect(handleCurrentBranchOffice, [currentBranchOffice]);

  const userData = () => {
    if (id === 0) dispatch(validateToken());
  };

  useEffect(userData, []);

  const handleFailureRequest = error => {
    dispatch(sendAlert({ kind: 'error', message: error?.response?.data?.message }));
  };

  const handleSuccessDownload = response => downloadFile(response);

  const downloadIndex = (format, params) => {
    indexProjectDetailsRequest({
      dispatch,
      params: { ...params, display_length: 100000, ...defaultParams },
      format,
      successCallback: handleSuccessDownload,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <Row className="px-3">
      <Col md={12} className="mx-auto" xs={12}>
        <Row className="mt-4">
          <Col md={10}>
            <h2 className="text-uppercase mb-4">Asociar Compra</h2>
          </Col>
          <Col md={2}>
            <Button variant="primary" block onClick={() => downloadIndex('xlsx', queries)}>
              Exportar
            </Button>
          </Col>
        </Row>
        {defaultParams.branch_office_id && (
          <ProjectDetailDataTable columns={columns} defaultParams={defaultParams} setQueries={setQueries} filterId="AssociatePurchaseIndex"/>
        )}
      </Col>
    </Row>
  );
};

export default AssociatePurchaseIndex;
